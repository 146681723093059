import { isHotkey } from 'is-hotkey'

export const isEnterHotkey = isHotkey('enter')

export const isEscHotkey = isHotkey('esc')

export const isQuickSwitcher = isHotkey('mod+k')

export const isArrowUp = isHotkey('up')

export const isArrowDown = isHotkey('down')
